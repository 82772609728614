/* Existing CSS for font face and icon styles */
@font-face {
  font-family: "app-icons-font";
  src: url("./font/ai-icon.woff?u98a1e") format("woff"),
  url("./font/ai-icon.ttf?u98a1e") format("truetype"),
  url("./font/ai-icon.eot?u98a1e#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ai-icon-"],
[class*=" ai-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "app-icons-font" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Utility classes for different icon sizes */
.ai-icon-1x {
  font-size: 1em; /* Default size */
}
.ai-icon-1-5x {
  font-size: 1.5em; /* Twice the default size */
}
.ai-icon-2x {
  font-size: 2em; /* Twice the default size */
}

.ai-icon-3x {
  font-size: 3em; /* Three times the default size */
}

.ai-icon-4x {
  font-size: 4em; /* Four times the default size */
}

/* Utility classes for rotating icons */
.ai-icon-rotate-90 {
  transform: rotate(90deg);
}

.ai-icon-rotate-180 {
  transform: rotate(180deg);
}

.ai-icon-rotate-270 {
  transform: rotate(270deg);
}

.ai-icon-flip-horizontal {
  transform: scaleX(-1);
}

.ai-icon-flip-vertical {
  transform: scaleY(-1);
}
