:root {
  --black: #000;
  --white: #fff;
  --primary-color-25: #E6E5F2;
  --primary-color-50: #DEDDE9;
  --primary-color-100: #B7B6C5;
  --primary-color-200: #9291A8;
  --primary-color-300: #6E6D8B;
  --primary-color-400: #4A496F;
  --primary-color-500: #262353;
  --primary-color-600: #201E44;
  --primary-color-700: #191836;
  --primary-color-800: #131228;
  --primary-color-900: #0D0C1C;
  --primary-color-950: #080711;
  --error-color-25: #FFFBFA;
  --error-color-50: #FEF3F2;
  --error-color-100: #FEE4E2;
  --error-color-200: #FECDCA;
  --error-color-300: #FDA29B;
  --error-color-400: #F97066;
  --error-color-500: #F04438;
  --error-color-600: #D92D20;
  --error-color-700: #B42318;
  --error-color-800: #912018;
  --error-color-900: #7A271A;
  --error-color-950: #55160C;
  --warning-color-25: #FFFCF5;
  --warning-color-50: #FFFAEB;
  --warning-color-100: #FEF0C7;
  --warning-color-200: #FEDF89;
  --warning-color-300: #FEC84B;
  --warning-color-400: #FDB022;
  --warning-color-500: #F79009;
  --warning-color-600: #DC6803;
  --warning-color-700: #B54708;
  --warning-color-800: #93370D;
  --warning-color-900: #7A2E0E;
  --warning-color-950: #4E1D09;
  --success-color-25: #F6FEF9;
  --success-color-50: #ECFDF3;
  --success-color-100: #DCFAE6;
  --success-color-200: #ABEFC6;
  --success-color-300: #75E0A7;
  --success-color-400: #47CD89;
  --success-color-500: #17B26A;
  --success-color-600: #079455;
  --success-color-700: #067647;
  --success-color-800: #085D3A;
  --success-color-900: #074D31;
  --success-color-950: #053321;
  --gray-color-25: #FCFCFD;
  --gray-color-50: #F9FAFB;
  --gray-color-100: #F2F4F7;
  --gray-color-200: #EAECF0;
  --gray-color-300: #D0D5DD;
  --gray-color-400: #98A2B3;
  --gray-color-500: #667085;
  --gray-color-600: #475467;
  --gray-color-700: #344054;
  --gray-color-800: #1D2939;
  --gray-color-900: #101828;
  --gray-color-950: #0C111D;
  --tomato-color-25: #FFEDE9;
  --tomato-color-50: #FEE0DB;
  --tomato-color-100: #FDCBC2;
  --tomato-color-200: #FCB1A3;
  --tomato-color-300: #FC9885;
  --tomato-color-400: #FB7E67;
  --tomato-color-500: #FA654B;
  --tomato-color-600: #D1543F;
  --tomato-color-700: #A74431;
  --tomato-color-800: #7D3225;
  --tomato-color-900: #532119;
  --tomato-color-950: #32140F;
  --winter-wizard-color-25: #F1F9FF;
  --winter-wizard-color-50: #ECF7FF;
  --winter-wizard-color-100: #DFF2FE;
  --winter-wizard-color-200: #CFEBFE;
  --winter-wizard-color-300: #C0E4FE;
  --winter-wizard-color-400: #B0DEFD;
  --winter-wizard-color-500: #A0D7FD;
  --winter-wizard-color-600: #85B3D3;
  --winter-wizard-color-700: #6B8FA9;
  --winter-wizard-color-800: #506B7E;
  --winter-wizard-color-900: #354854;
  --winter-wizard-color-950: #202B33;
  --powder-blue-color-25: #FAFFFD;
  --powder-blue-color-50: #F1FBF8;
  --powder-blue-color-100: #E8F9F4;
  --powder-blue-color-200: #DDF5EE;
  --powder-blue-color-300: #D2F2E8;
  --powder-blue-color-400: #C6EFE3;
  --powder-blue-color-500: #BBECDD;
  --powder-blue-color-600: #9CC5B8;
  --powder-blue-color-700: #7D9D93;
  --powder-blue-color-800: #5D766E;
  --powder-blue-color-900: #3E4F4A;
  --powder-blue-color-950: #252F2C;
  --pale-lavender-color-25: #FBF9FF;
  --pale-lavender-color-50: #F8F3FF;
  --pale-lavender-color-100: #F3EBFE;
  --pale-lavender-color-200: #EDE1FE;
  --pale-lavender-color-300: #E7D8FE;
  --pale-lavender-color-400: #E1CEFD;
  --pale-lavender-color-500: #DBC4FD;
  --pale-lavender-color-600: #B6A3D3;
  --pale-lavender-color-700: #9283A9;
  --pale-lavender-color-800: #6D627E;
  --pale-lavender-color-900: #494154;
  --pale-lavender-color-950: #2C2733;
  --tuula-font-family: "Onest";
  --sidebar-line-color: #262353;
  --sidebar-font-color: #fff;
  --sidebar-bg-color: #262353;
}

$border-radius: 4.19874px;
$tuula-font-family: var(--tuula-font-family);
$white:var(--white);





// sidebar
$sidebar-bg-color: var(--primary-color-500);
$sidebar-font-color: var(--white);
$sidebar-menu-item-selected-color: var(--primary-color-400);
$sidebar-menu-item-focus-color: var(--primary-color-25);
$sidebar-menu-item-selected-font-color: var(--pale-lavender-color-600);
$sidebar-line-color: var(--primary-color-500);

// button

$button-bg-color: var(--primary-color-500);
$button-bg-hover-color: var(--primary-color-400);
$button-line-color: var(--primary-color-800);
$border-radius: 4px;
$button-font-color: var(--white);
$font-normal: 12px;
$button-cancel-bg-color: var(--primary-color-50);
$button-cancel-bg-hover-color: var(--gray-color-300);
$button-cancel-color: var(--primary-color-800);
$button-cancel-border-color: var(--gray-color-200);
//badge
$warning-bg-color: var(--warning-color-50);
$warning-color: var(--warning-color-700);
$warning-border-color: var(--warning-color-200);
$success-bg-color: var(--success-color-50);
$success-color: var(--success-color-700);
$success-border-color: var(--success-color-200);
$error-bg-color: var(--error-color-50);
$error-color: var(--error-color-700);
$error-border-color: var(--error-color-200);
$info-bg-color: var(--winter-wizard-color-50);
$info-color: var(--winter-wizard-color-700);
$info-border-color: var(--winter-wizard-color-200);
$unknown-bg-color: var(--pale-lavender-color-50);
$unknown-color: var(--pale-lavender-color-700);
$unknown-border-color: var(--pale-lavender-color-200);
$inactive-bg-color: var(--gray-color-50);
$inactive-color: var(--gray-color-700);
$inactive-border-color: var(--gray-color-200);
$pink-bg-color: var(--tomato-color-50);
$pink-color: var(--tomato-color-700);
$pink-border-color: var(--tomato-color-200);
// notify
$notify-bg-success: var(--success-color-100);
$notify-font-color-success: var(--success-color-700);
$notify-bg-warning: var(--warning-color-100);
$notify-font-color-warning: var(--warning-color-700);
$notify-bg-error: var(--error-color-100);
$notify-font-color-error: var(--error-color-700);
$notify-bg-info: var(--winter-wizard-color-100);
$notify-font-color-info: var(--winter-wizard-color-700);
// checkbox
$checkbox-unchecked-bg-color: var(--white);
$checkbox-unchecked-border-color: var(--gray-color-300);

$checkbox-checked-bg-color: var(--primary-color-50);
$checkbox-checked-border-color: var(--primary-color-600);
$checkbox-checked-checkmark-color: var(--primary-color-600);

$checkbox-disabled-unchecked-bg-color: var(--gray-color-100);
$checkbox-disabled-checked-bg-color: var(--gray-color-100);
$checkbox-disabled-checked-checkmark-color: var(--gray-color-300);

$form-title-font-color: var(--primary-color-700);


$popup-notify-bg-color: var(--primary-color-500);
$popup-notify-font-color: var(--white);
$popup-warning-bg-color: var(--warning-color-500);
$popup-warning-font-color: var(--white);
$button-font-color: var(--white);

