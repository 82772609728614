@use "sass:math";

$grid-columns: 12;
$gutter-width: 10px;

$grid-breakpoints: (
  xs: 0, // Extra small devices (phones, less than 576px)
  sm: 576px, // Small devices (landscape phones, 576px and up)
  md: 768px, // Medium devices (tablets, 768px and up)
  lg: 992px, // Large devices (desktops, 992px and up)
  xl: 1200px, // Extra large devices (large desktops, 1200px and up)
  xxl: 1400px // The biggest large devices (large desktops, 1400px and up)
);

@mixin make-gutters {
  padding-right: calc($gutter-width / 2);
  padding-left: calc($gutter-width / 2);
}

@mixin make-col($size) {
  flex: 0 0 math.percentage(math.div($size, $grid-columns));
  max-width: math.percentage(math.div($size, $grid-columns));
}

@mixin make-col-ready() {
  flex: 0 0 100%;
  max-width: 100%;
}

@mixin create-col-default-classes($infix,$breakpoint-max) {
  .col#{$infix} {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col#{$infix}-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  @media (max-width: $breakpoint-max) {
    .col#{$infix} {
      @include make-col-ready();
    }
    .col#{$infix}-auto {
      @include make-col-ready();
    }
  }
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: calc(-1 * $gutter-width / 2);
  margin-left: calc(-1 * $gutter-width / 2);
}


@each $screen-size-name, $breakpoint-max in $grid-breakpoints { 

  $infix: -#{$screen-size-name};
  
  @if ( $screen-size-name == "xs" ) {
    $infix: ''
  }

  @include create-col-default-classes($infix,$breakpoint-max);

  @for $i from 1 through $grid-columns {
    .col#{$infix}-#{$i} {
      @include make-col($i);
    }

    @media (max-width: $breakpoint-max) {
      .col#{$infix}-#{$i} {
        @include make-col-ready();
      }
    }
  }

} 

